if (document.getElementById('mortgageCalculator')) {
	(function () {
		let formElements;
		let headerHeight;

		// Select the range input elements
		const borrowAmountSlider = document.getElementById('borrowAmountSlider');
		const yearsSlider = document.getElementById('yearsSlider');

		// Add event listeners to listen for input changes on both sliders
		borrowAmountSlider.addEventListener('input', function () {
			updateSliderBackground(borrowAmountSlider);
		});

		yearsSlider.addEventListener('input', function () {
			updateSliderBackground(yearsSlider);
		});

		// Function to update the background of the slider
		function updateSliderBackground(slider) {
			// Get the current value of the slider
			const value = slider.value;
			// Calculate the percentage based on the value (assuming linear gradient)
			const percentage = (value - slider.min) / (slider.max - slider.min) * 100;

			// Calculate the opposite percentage
			const oppositePercentage = 100 - percentage;

			// Update the background linear gradient in the style attribute
			slider.style.background = `linear-gradient(to right, #FF7D45 ${percentage}%, #E6E6E6 ${percentage}%)`;
		}

		function setError(element, errorElement) {
			element.classList.add('error');
			errorElement.classList.remove('d-none');
		}

		function clearError(element, errorElement) {
			element.classList.remove('error');
			errorElement.classList.add('d-none');
		}

		function formatNumberInput(inputElement) {
			const value = inputElement.value.replace(/,/g, '').trim();
			if (value === "") {
				inputElement.value = ''; // Keep it empty if no number is entered yet
			} else {
				const number = parseFloat(value);
				if (!isNaN(number)) {
					inputElement.value = number.toLocaleString();
				}
			}
		}

		function unformatNumberInput(inputElement) {
			const value = inputElement.value.replace(/,/g, '').trim();
			inputElement.value = value;
		}

		function getHeaderHeight() {
			const header = document.querySelector('header');
			return header ? header.offsetHeight : 0;
		}

		function initializeForm() {
			formElements = {
				outstandingBalance: document.getElementById('outstandingBalance'),
				homeWorth: document.getElementById('homeWorth'),
				borrowYears: document.getElementById('borrowYears'),
				borrowAmount: document.getElementById('borrowAmount'),
				borrowAmountSlider: document.getElementById('borrowAmountSlider'),
				yearsSlider: document.getElementById('yearsSlider'),
				outstandingError: document.getElementById('outstanding-error'),
				worthError: document.getElementById('worth-error'),
				termError: document.getElementById('term-error'),
				borrowError: document.getElementById('borrow-error'),
				resultsSection: document.querySelector('.calc-results')
			};

			// Set default values
			formElements.borrowAmount.value = '15,000';
			formElements.borrowYears.value = '5';
			formElements.borrowAmountSlider.value = '15000';
			formElements.yearsSlider.value = '5';

			// Add event listeners
			formElements.borrowAmount.addEventListener('focusout', function () {
				formatNumberInput(this);
			});

			formElements.borrowYears.addEventListener('focusout', function () {
				formatNumberInput(this);
			});

			formElements.borrowAmount.addEventListener('input', function () {
				formElements.borrowAmountSlider.value = this.value.replace(/,/g, '');
				updateSliderBackground(formElements.borrowAmountSlider); // Update slider background when input changes
				hideResults(); // Hide results when input changes
			});

			formElements.borrowAmountSlider.addEventListener('input', function () {
				formElements.borrowAmount.value = this.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
				updateSliderBackground(this); // Update slider background when slider changes
				hideResults(); // Hide results when slider changes
			});

			formElements.borrowYears.addEventListener('input', function () {
				formElements.yearsSlider.value = this.value.replace(/,/g, '');
				updateSliderBackground(formElements.yearsSlider); // Update slider background when input changes
				hideResults(); // Hide results when input changes
			});

			formElements.yearsSlider.addEventListener('input', function () {
				formElements.borrowYears.value = this.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
				updateSliderBackground(this); // Update slider background when slider changes
				hideResults(); // Hide results when slider changes
			});

			formElements.outstandingBalance.addEventListener('input', hideResults);
			formElements.homeWorth.addEventListener('input', hideResults);

			// Set event listener for the submit button
			document.getElementById('calculator-form__submit').addEventListener('click', onSubmit);

			headerHeight = getHeaderHeight();
		}

		function hideResults() {
			formElements.resultsSection.classList.remove('show-results');
		}

		function onSubmit(e) {
			e.preventDefault();
			validateAndProcessForm();
		}

		function validateAndProcessForm() {
			let formError = false;

			// Clear any previous errors
			clearAllErrors();

			// Validate all fields
			formError |= !validateField(formElements.outstandingBalance, formElements.outstandingError, value => value > 0);
			formError |= !validateField(formElements.homeWorth, formElements.worthError, value => value > 0);
			formError |= !validateField(formElements.borrowYears, formElements.termError, value => value >= 5 && value <= 30);
			formError |= !validateField(formElements.borrowAmount, formElements.borrowError, value => value >= 15000 && value <= 500000);

			if (!formError) {
				calculateResults();
				formElements.resultsSection.classList.add('show-results');
				scrollToResults();
			}
		}

		function validateField(element, errorElement, condition) {
			const value = element.value.replace(/,/g, '').trim();
			const isValid = condition(Number(value));
			if (!isValid) {
				setError(element, errorElement);
			}
			return isValid;
		}

		function clearAllErrors() {
			clearError(formElements.outstandingBalance, formElements.outstandingError);
			clearError(formElements.homeWorth, formElements.worthError);
			clearError(formElements.borrowYears, formElements.termError);
			clearError(formElements.borrowAmount, formElements.borrowError);
		}

		function calculateResults() {
			// Define default values
			const defaultRate = 0.0953;
			const defaultDischargeFee = 95;
			const defaultBrokerFeePercentage = 1;
			const defaultLenderFee = 695;

			// Use values from window.calculatorValues if available, otherwise use defaults
			const rate = !isNaN(parseFloat(window.calculatorValues?.rate)) ? parseFloat(window.calculatorValues?.rate) : defaultRate;
			const dischargeFee = !isNaN(parseFloat(window.calculatorValues?.dischargeFee)) ? parseFloat(window.calculatorValues?.dischargeFee) : defaultDischargeFee;
			const brokerFeePercentage = !isNaN(parseFloat(window.calculatorValues?.brokerFee)) ? parseFloat(window.calculatorValues?.brokerFee) : defaultBrokerFeePercentage;
			const lenderFee = !isNaN(parseFloat(window.calculatorValues?.lenderFee)) ? parseFloat(window.calculatorValues?.lenderFee) : defaultLenderFee;
			const borrowAmount = parseInt(formElements.borrowAmount.value.replace(/,/g, ''), 10);
			const termYears = parseInt(formElements.borrowYears.value.replace(/,/g, ''), 10);
			const homeWorth = parseInt(formElements.homeWorth.value.replace(/,/g, ''), 10);

			const LTV = (borrowAmount / homeWorth) * 100;

			const pricePremium = LTV > 60 ? 0.01 : 0;
			const adjustedRate = rate + pricePremium;

			const brokerFee = borrowAmount * (brokerFeePercentage / 100);
			const grossAdvance = borrowAmount + brokerFee + lenderFee;
			const monthlyRate = adjustedRate / 12;
			const numPayments = termYears * 12;

			const imr = (grossAdvance * monthlyRate) / (1 - Math.pow(1 + monthlyRate, -numPayments));
			const totalPayable = imr * numPayments + dischargeFee;

			displayResults(borrowAmount, imr, adjustedRate, totalPayable);
		}

		function displayResults(loanAmount, monthlyPayment, interestRate, total) {
			document.querySelector('.loan-amount').textContent = `${loanAmount.toLocaleString()}.00`;
			document.querySelector('.monthly-repayment').textContent = `${monthlyPayment.toLocaleString(undefined, { maximumFractionDigits: 2 })}`;
			document.querySelector('.interest-rate').textContent = `${(interestRate * 100).toFixed(2)}`;
			document.querySelector('.total-repayable').textContent = `${total.toLocaleString(undefined, { maximumFractionDigits: 2 })}`;
		}

		function scrollToResults() {
			const scrollAmount = formElements.resultsSection.offsetTop - headerHeight;
			window.scrollTo({
				top: scrollAmount,
				behavior: "smooth"
			});
		}

		document.addEventListener('DOMContentLoaded', initializeForm);
	})();
}

// Select all elements with class .broker-card__submit
const submitButtons = document.querySelectorAll('.broker-card__submit');

// Add event listener to each submit button
submitButtons.forEach(button => {
    button.addEventListener('click', function() {
        // Find the nearest parent element with class .broker-card
        const brokerCard = button.closest('.broker-card');

        // Select the elements with classes .cta and .success inside the nearest .broker-card
        const ctaSection = brokerCard.querySelector('.cta');
        const successSection = brokerCard.querySelector('.success');

        // Remove the class d-none from .success and add the class d-block
        successSection.classList.remove('d-none');
        successSection.classList.add('d-block');

        // Add the class d-none to .cta
        ctaSection.classList.add('d-none');
    });
});


// open and close the mobile menu
document.addEventListener("DOMContentLoaded", function() {
    // Get reference to the navbar-toggler button and the navbar itself
    const navbarToggler = document.querySelector('.navbar-toggler');
    const navbar = document.querySelector('nav.navbar');

    // Add click event listener to the navbar-toggler button
    navbarToggler.addEventListener('click', function() {
        // Toggle the 'is-active' class on the navbar
        navbar.classList.toggle('is-active');
    });
});


document.addEventListener('DOMContentLoaded', function() {
    var bodyElement = document.body;

    window.addEventListener('scroll', function() {
        if (window.scrollY > 1) {
            document.documentElement.classList.add('is-scrolling');
            bodyElement.classList.add('is-scrolling');
        } else {
            document.documentElement.classList.remove('is-scrolling');
            bodyElement.classList.remove('is-scrolling');
        }
    });
});

document.querySelectorAll('.number-separator').forEach((item) => {
	item.addEventListener('input', (e) => {
		if (/^[0-9.,]+$/.test(e.target.value)) {
			e.target.value = parseFloat(
				e.target.value.replace(/,/g, '')
			).toLocaleString('en');
		} else {
			e.target.value = e.target.value.substring(0, e.target.value.length - 1);
		}
	});
});